<template>
<div class="driver-insert">
    <b-media class="mb-2 ml-2">
        <template #aside>
            <b-avatar ref="previewEl" :src="avatar ? driver.photo = avatar : driver.photo" variant="light-primary" size="90px" rounded />
        </template>
        <div class="d-flex flex-wrap">
            <b-button variant="primary" @click="$refs.refInputEl.click()">
                <input ref="refInputEl" type="file" class="d-none" @input="inputImageRenderer">
                <span class="d-none d-sm-inline">upload</span>
            </b-button>
            <b-button variant="outline-secondary" @click="onDeleteImage" class="ml-1">
                <span class="d-none d-sm-inline">Remove</span>
            </b-button>
        </div>
    </b-media>

    <b-form-group label="Driver ID" label-for="basicInput">
        <b-form-input id="basicInput" v-model="driver.driver_id" placeholder="Enter driver ID.." />
    </b-form-group>

    <b-form-group label="Driver Nick Name" label-for="basicInput">
        <b-form-input id="basicInput" v-model="driver.nick_name" placeholder="Enter driver nick name.." />
    </b-form-group>

    <b-form-group label="Driver Name" label-for="basicInput">
        <b-form-input id="basicInput" v-model="driver.driver_name" placeholder="Enter driver name.." />
    </b-form-group>

    <b-form-group label="SSN" label-for="basicInput" v-if="loggedUser.role === 'Super Admin' || loggedUser.role === 'Admin'">
        <b-form-input id="basicInput" v-model="driver.ssn" placeholder="Enter ssn.." />
    </b-form-group>

    <b-form-group label="Email" label-for="basicInput">
        <b-form-input id="basicInput" v-model="driver.email" placeholder="Enter driver email.." />
    </b-form-group>

    <!-- <b-form-group label="Password" label-for="basicInput">
        <b-form-input id="basicInput" type="password" v-model="driver.password"  placeholder="Enter driver password.." />
    </b-form-group> -->

    <!-- with prop prepend  -->
    <b-input-group style="width: 93%" prepend="+">
        <b-form-input type="number" v-model="driver.phone" placeholder="Enter Phone number.." />
    </b-input-group>

    <b-form-group label="Driver DOB" label-for="basicInput">
        <b-row class="d-flex align-items-center">
            <b-col cols="7">
                <b-form-datepicker
                    id="datepicker-dateformat3"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="en"
                    v-model="driver.dob"
                ></b-form-datepicker>
            </b-col>
            <b-col cols="3">
                <b-button variant="outline-secondary" @click="removeDateDate">Remove</b-button>
            </b-col>
        </b-row>
    </b-form-group>

    <b-form-group label="License" label-for="basicInput">
        <b-form-input id="basicInput" v-model="driver.license" placeholder="Enter driver license.." />
    </b-form-group>

    
    <b-form-group label="Licence Expiration" label-for="basicInput">
        <b-row class="d-flex align-items-center">
            <b-col cols="12">
                <b-form-datepicker
                    id="datepicker-dateformat4"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="en"
                    v-model="driver.licence_expiration"
                ></b-form-datepicker>
            </b-col>
            
        </b-row>
    </b-form-group>

       
    <b-form-group label="Medical Card Expiration" label-for="basicInput">
        <b-row class="d-flex align-items-center">
            <b-col cols="12">
                <b-form-datepicker
                    id="datepicker-dateformat5"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="en"
                    v-model="driver.medical_card_expiration"
                ></b-form-datepicker>
            </b-col>
            
        </b-row>
    </b-form-group>

    <b-form-group label="Address" label-for="basicInput">
        <b-form-input id="basicInput" v-model="driver.address" placeholder="Enter driver address.." />
    </b-form-group>


    <b-form-group label="Domicile" label-for="basicInput">
        <b-form-input id="basicInput" v-model="driver.domicile" placeholder="Enter driver domicile.." />
    </b-form-group>

    <b-form-group label="Pay Type" v-if="loggedUser.role === 'Super Admin' || loggedUser.role === 'Admin'">
        <v-select v-model="driver.pay_type"  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :options="payTypeoption" />
    </b-form-group>

    <!-- with prop prepend  -->
    <b-input-group style="width: 93%" prepend="$" v-if="loggedUser.role === 'Super Admin' || loggedUser.role === 'Admin'">
        <b-form-input type="number" v-model="driver.pay_value"  placeholder="Enter pay.." />
    </b-input-group>

    <b-form-group>
        <v-select v-model="driver.work_status" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :options="option" />
    </b-form-group>

    <b-form-group label="Uniforms" label-for="basicInput">
        <div class="uniform">
            <b-row>
                <b-col cols="4" md="4" class="mb-md-0 mb-2">
                    <label>Shirt </label>                    
                    <v-select v-model="driver.uniforms.shirt" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :options="uniformOption" />
                </b-col>
                <b-col cols="4" md="4" class="mb-md-0 mb-2">
                    <label>Pants</label>
                    <b-form-input type="text" v-model="driver.uniforms.pants" />
                </b-col>
                <b-col cols="4" md="4" class="mb-md-0 mb-2">
                    <label>Jacket</label>
                    <v-select v-model="driver.uniforms.jacket" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"  :options="uniformOption" />
                </b-col>
            </b-row>
        </div>        
    </b-form-group>

    <b-form-group label="Hire Date" label-for="basicInput">
        <b-row class="d-flex align-items-center">
            <b-col cols="7">
                <b-form-datepicker
                    id="datepicker-dateformat1"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="en"
                    v-model="driver.hire_date"
                ></b-form-datepicker>
            </b-col>
            <b-col cols="3">
                <b-button variant="outline-secondary" @click="removeHireDate">Remove</b-button>
            </b-col>
        </b-row>
    </b-form-group>

    <b-form-group label="Termination Date" label-for="basicInput">
        <b-row class="d-flex align-items-center">
            <b-col cols="7">
                <b-form-datepicker
                    id="datepicker-dateformat2"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="en"
                    v-model="driver.termination_date"
                ></b-form-datepicker>
            </b-col>
            <b-col cols="3">
                <b-button variant="outline-secondary" @click="removeTermDate">Remove</b-button>
            </b-col>
        </b-row>
    </b-form-group>

    <b-form-group label="Notes" label-for="task-description">
        <quill-editor id="quil-content" v-model="driver.notes" :options="editorOption" class="border-bottom-0 notes-box" />
        <div id="quill-toolbar" class="d-flex justify-content-end border-top-0">
            <!-- Add a bold button -->
            <button class="ql-bold" />
            <button class="ql-italic" />
            <button class="ql-underline" />
        </div>
    </b-form-group>

    <b-button @click="onSaveDriver" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
        Save
    </b-button>

</div>
</template>

<script>
import {
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
} from "vue-xlsx/dist/vue-xlsx.es.js"
import {
    BButton,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BMedia,
    BInputGroupPrepend,
    BRow,
    BCol,
    BFormDatepicker
} from 'bootstrap-vue'
import axios from 'axios'
import {
    quillEditor
} from 'vue-quill-editor'
import {
    serverUri
} from '@/config'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {
    ref
} from '@vue/composition-api'
import {
    getUserData
} from '@/auth/utils'
import {
    mapState,
    mapActions
} from 'vuex'
import {
    useInputImageRenderer
} from '@core/comp-functions/forms/form-utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        XlsxRead,
        XlsxTable,
        XlsxSheets,
        XlsxJson,
        XlsxWorkbook,
        XlsxSheet,
        XlsxDownload,
        BMedia,
        BButton,
        BAvatar,
        BListGroup,
        BListGroupItem,
        BCardText,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        vSelect,
        quillEditor,
        BRow,
        BCol,
        BFormDatepicker
    },
    data() {
        return {
            loggedUser : getUserData(),
            sidebarItems: [{
                    title: 'Dashboard',
                    icon: 'GridIcon'
                },
                {
                    title: 'Analytics',
                    icon: 'ActivityIcon'
                },
                {
                    title: 'History',
                    icon: 'RefreshCwIcon'
                },
                {
                    title: 'Configuration',
                    icon: 'SettingsIcon'
                },
                {
                    title: 'Profile',
                    icon: 'UserIcon'
                },
            ],
            option: [{
                title: 'Working',
                value: 1
            }, {
                title: 'No longer working',
                value: 0
            }],
            payTypeoption: [{
                title: 'Per Mile',
                value: 1
            }, 
            {
                title: 'Hourly',
                value: 2
            },
            {
                title: 'Per Day',
                value: 3
            }
        ],
            selectedImage: null,
            filter_status: null,
           
            uniformOption: [
                {
                    title: 'S',
                    label: 'S'
                },
                {
                    title: 'M',
                    label: 'M'
                },
                {
                    title: 'L',
                    label: 'L'
                },
                {
                    title: 'XL',
                    label: 'XL'
                },
                {
                    title: 'XXL',
                    label: 'XXL'
                },
                {
                    title: '3XL',
                    label: '3XL'
                },
            ]
        }
    },
    directives: {
        Ripple,
    },
    computed: {
        ...mapState({
            driver: state => state.driversStore.driversEdit,
            driversList: state => state.driversStore.driversList
        }),

    },
 
    methods: {
        ...mapActions('driversStore', ['addDriverAction', 'editDriverAction', 'updateDriverAction']),
        
        validateDriverForm(driver) {
            const fieldsToValidate = {
                driver_id: "Please enter driver id",
                nick_name: "Please enter driver nick name",
                driver_name: "Please enter driver name",
                ssn: "Please enter driver ssn",
                email: "Please enter driver email",
                phone: "Please enter driver phone",
                dob: "Please enter driver dob",
                license: "Please enter driver license",
                address: "Please enter driver address",
                domicile: "Please enter driver domicile",
                pay_type: "Please enter driver pay type",
                pay_value: "Please enter pay"
            };

            for (let field in fieldsToValidate) {
                if (!driver[field] || driver[field] === '') {
                    this.showToast('danger', 'Warning', fieldsToValidate[field]);
                    return false;
                }
            }
            return true;
        },

        onSaveDriver() {
            if (this.driver.id) {
                this.updateDriver();
            } else {
                if (this.validateDriverForm(this.driver)) {
                    this.saveDriver();
                }
            }
        },

        updateDriver() {
            axios.post(`${serverUri}/drivers/updatedriver/${this.driver.id}`, { ...this.driver })
                .then(res => {
                    this.showToast('success', 'Success', `The driver ${this.driver.driver_name} has been updated`);
                });
        },

        saveDriver() {
            const userData = getUserData();
            axios.post(`${serverUri}/drivers/savedriver`, {
                ...this.driver,
                company_id: userData.company_id
            }).then(res => {
                if (res.data.status) {
                    if (res.data.length) this.addDriverAction(res.data.data.map(item => item.work_status === this.filter_status.value && item));
                    this.showToast('success', 'Success', `The driver ${this.driver.driver_name} has been added`);
                } else {
                    this.showToast('danger', 'Warning', res.data.message);
                }
            });
        },

        showToast(variant, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: 'BellIcon',
                    text: text,
                    variant,
                },
            })
        },
    


        removeHireDate() {
            this.driver.hire_date = '';
        },

        removeDateDate() {
            this.driver.dob = '';
        },
        removeTermDate() {
            this.driver.termination_date = '';
        }
    },
    props: {
        avatar: {
            type: String,
        },
    },
    created() {
        this.filter_status = this.driver.work_status;
        // datee = new Date();
        // this.selectedMonth = this.months[date.getMonth() - 1];
        // const currentYear = datee.getFullYear();
        // this.selectedYear = currentYear;
        // this.selectedDate = date;

        // this.driver.termination_date = datee;
    },
    setup(props) {
        const refInputEl = ref(null)
        const previewEl = ref(null)

        const {
            inputImageRenderer
        } = useInputImageRenderer(refInputEl, base64 => {
            // eslint-disable-next-line no-param-reassign

            props.avatar = base64
        })

        const onDeleteImage = () => {
            props.avatar = null
        }

        const editorOption = {
            modules: {
                toolbar: '#quill-toolbar',
            },
            placeholder: 'Notes...',
        }

        return {
            onDeleteImage,
            refInputEl,
            previewEl,
            inputImageRenderer,
            editorOption
        }
    }
}
</script>

<style scoped>
.driver-insert .form-group,
.input-group,
button {
    margin: 15px 15px;
}
</style><style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
    ::v-deep .vs__dropdown-toggle {
        padding-left: 0;
    }
}

#quil-content ::v-deep {
    >.ql-container {
        border-bottom: 0;
    }

    +#quill-toolbar {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
}

.notes-box {
    height: 150px !important;
}
</style>
